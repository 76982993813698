import * as i2 from '@angular/cdk/a11y';
import * as i6 from '@angular/cdk/bidi';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import * as i3 from '@angular/cdk/platform';
import * as i5 from '@angular/cdk/portal';
import { CdkPortalOutlet, PortalModule } from '@angular/cdk/portal';
import * as i1 from '@angular/cdk/scrolling';
import { CdkScrollable } from '@angular/cdk/scrolling';
import * as i4 from '@angular/common';
import { DOCUMENT, CommonModule } from '@angular/common';
import * as i0 from '@angular/core';
import { InjectionToken, forwardRef, Component, Inject, Optional, ViewChild, Input, ContentChildren, ContentChild, NgModule } from '@angular/core';
import { MatDrawerContent, MatDrawer, MatDrawerContainer, MatSidenavModule } from '@angular/material/sidenav';

/* eslint-disable @typescript-eslint/member-ordering */
const _c0 = ["*"];
const _c1 = [[["cx-sidebar-header"]], [["cx-sidebar-content"]], [["cx-sidebar-footer"]]];
const _c2 = ["cx-sidebar-header", "cx-sidebar-content", "cx-sidebar-footer"];
function CxSidebarComponent_ng_container_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵprojection(1);
    i0.ɵɵprojection(2, 1);
    i0.ɵɵprojection(3, 2);
    i0.ɵɵelementContainerEnd();
  }
}
function CxSidebarComponent_ng_template_1_ng_template_1_Template(rf, ctx) {}
function CxSidebarComponent_ng_template_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "section", 2);
    i0.ɵɵtemplate(1, CxSidebarComponent_ng_template_1_ng_template_1_Template, 0, 0, "ng-template", 3);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵproperty("cdkPortalOutlet", ctx_r0.componentPortal);
  }
}
const _c3 = [[["cx-sidebar"]], [["cx-sidebar-container-content"]]];
const _c4 = ["cx-sidebar", "cx-sidebar-container-content"];
function CxSidebarContainerComponent_div_0_Template(rf, ctx) {
  if (rf & 1) {
    const _r1 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div", 1);
    i0.ɵɵlistener("click", function CxSidebarContainerComponent_div_0_Template_div_click_0_listener() {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1._onBackdropClicked());
    });
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵclassProp("mat-drawer-shown", ctx_r1._isShowingBackdrop());
  }
}
const MAT_DRAWER_CONTAINER = new InjectionToken('MAT_DRAWER_CONTAINER');
class CxSidebarContainerContentComponent extends MatDrawerContent {
  constructor(changeDetectorRef, container, elementRef, scrollDispatcher, ngZone) {
    super(changeDetectorRef, container, elementRef, scrollDispatcher, ngZone);
  }
  static {
    this.ɵfac = function CxSidebarContainerContentComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || CxSidebarContainerContentComponent)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(forwardRef(() => CxSidebarContainerComponent)), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i1.ScrollDispatcher), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: CxSidebarContainerContentComponent,
      selectors: [["cx-sidebar-container-content"]],
      hostAttrs: [1, "mat-drawer-content", "mat-sidenav-content"],
      standalone: false,
      features: [i0.ɵɵProvidersFeature([{
        provide: CdkScrollable,
        useExisting: CxSidebarContainerContentComponent
      }]), i0.ɵɵInheritDefinitionFeature],
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function CxSidebarContainerContentComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      styles: [".mat-drawer-content[_nghost-%COMP%]{position:relative;z-index:1;display:block;height:100%}.mat-drawer-transition   .mat-drawer-content[_nghost-%COMP%]{transition-duration:.4s;transition-timing-function:cubic-bezier(.25,.8,.25,1);transition-property:transform,margin-left,margin-right}"]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CxSidebarContainerContentComponent, [{
    type: Component,
    args: [{
      selector: 'cx-sidebar-container-content',
      host: {
        class: 'mat-drawer-content mat-sidenav-content'
      },
      providers: [{
        provide: CdkScrollable,
        useExisting: CxSidebarContainerContentComponent
      }],
      standalone: false,
      template: "<ng-content></ng-content>\n",
      styles: [":host.mat-drawer-content{position:relative;z-index:1;display:block;height:100%}.mat-drawer-transition :host.mat-drawer-content{transition-duration:.4s;transition-timing-function:cubic-bezier(.25,.8,.25,1);transition-property:transform,margin-left,margin-right}\n"]
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: undefined,
    decorators: [{
      type: Inject,
      args: [forwardRef(() => CxSidebarContainerComponent)]
    }]
  }, {
    type: i0.ElementRef
  }, {
    type: i1.ScrollDispatcher
  }, {
    type: i0.NgZone
  }], null);
})();
class CxSidebarComponent extends MatDrawer {
  /**
   * ComponentPortal that will be used instead of content projection
   * in order to generate sidebar contents
   */
  get componentPortal() {
    return this.portal;
  }
  set componentPortal(value) {
    this.portal = value;
    if (this.portal !== null && this.portalHost !== undefined) {
      this.portalHost.attachComponentPortal(this.portal);
    }
  }
  /**
   * Whether the sidebar should have 'fixed' position
   *
   * @default true
   */
  get fixedInViewport() {
    return this.isFixedInViewport;
  }
  set fixedInViewport(value) {
    this.isFixedInViewport = coerceBooleanProperty(value);
  }
  constructor(elementRef, focusTrapFactory, platform, ngZone, interactivityChecker, doc) {
    super(elementRef,
    // MatDrawer wants FocusTrapFactory which is deprecated
    // We use ConfigurableFocusTrapFactory because it just works better
    // That's why we cast it to any
    focusTrapFactory,
    // This is a very ugly solution that disables focusing button that
    // opened sidebar, after closing it
    {
      focusVia: () => {}
    }, platform, ngZone, interactivityChecker, doc);
    this.portal = null;
    this.isFixedInViewport = true;
    this.position = 'end';
    this.mode = 'over';
    this.autoFocus = false;
    this.disableClose = true;
  }
  static {
    this.ɵfac = function CxSidebarComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || CxSidebarComponent)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i2.ConfigurableFocusTrapFactory), i0.ɵɵdirectiveInject(i3.Platform), i0.ɵɵdirectiveInject(i0.NgZone), i0.ɵɵdirectiveInject(i2.InteractivityChecker), i0.ɵɵdirectiveInject(DOCUMENT, 8));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: CxSidebarComponent,
      selectors: [["cx-sidebar"]],
      viewQuery: function CxSidebarComponent_Query(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵviewQuery(CdkPortalOutlet, 7);
        }
        if (rf & 2) {
          let _t;
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.portalHost = _t.first);
        }
      },
      hostAttrs: ["tabIndex", "-1", 1, "mat-drawer"],
      hostVars: 12,
      hostBindings: function CxSidebarComponent_HostBindings(rf, ctx) {
        if (rf & 2) {
          i0.ɵɵclassProp("mat-drawer-end", ctx.position === "end")("mat-drawer-over", ctx.mode === "over")("mat-drawer-push", ctx.mode === "push")("mat-drawer-side", ctx.mode === "side")("mat-drawer-fixed", ctx.fixedInViewport)("mat-drawer-opened", ctx.opened);
        }
      },
      inputs: {
        componentPortal: "componentPortal",
        fixedInViewport: "fixedInViewport"
      },
      standalone: false,
      features: [i0.ɵɵInheritDefinitionFeature],
      ngContentSelectors: _c2,
      decls: 3,
      vars: 2,
      consts: [["portal", ""], [4, "ngIf", "ngIfElse"], [1, "cx-sidebar-portal-container"], [3, "cdkPortalOutlet"]],
      template: function CxSidebarComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef(_c1);
          i0.ɵɵtemplate(0, CxSidebarComponent_ng_container_0_Template, 4, 0, "ng-container", 1)(1, CxSidebarComponent_ng_template_1_Template, 2, 1, "ng-template", null, 0, i0.ɵɵtemplateRefExtractor);
        }
        if (rf & 2) {
          const portal_r2 = i0.ɵɵreference(2);
          i0.ɵɵproperty("ngIf", ctx.componentPortal === null)("ngIfElse", portal_r2);
        }
      },
      dependencies: [i4.NgIf, i5.CdkPortalOutlet],
      styles: [".mat-drawer[_nghost-%COMP%]{position:relative;z-index:4;position:absolute;top:0;bottom:0;z-index:3;outline:0;box-sizing:border-box;color:inherit;transform:translate3d(-100%,0,0);max-width:484px;width:100%;height:100%;background:#fff;display:flex;flex-direction:column}.mat-drawer.mat-drawer-side[_nghost-%COMP%]{z-index:2}.mat-drawer.mat-drawer-end[_nghost-%COMP%]{right:0;transform:translate3d(100%,0,0)}.mat-drawer[style*=\"visibility: hidden\"][_nghost-%COMP%]{display:none}.mat-drawer[_nghost-%COMP%]:not(.mat-drawer-side){box-shadow:0 24px 48px #20202029}.mat-drawer-fixed[_nghost-%COMP%]{position:fixed}"]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CxSidebarComponent, [{
    type: Component,
    args: [{
      selector: 'cx-sidebar',
      host: {
        class: 'mat-drawer',
        tabIndex: '-1',
        '[class.mat-drawer-end]': 'position === "end"',
        '[class.mat-drawer-over]': 'mode === "over"',
        '[class.mat-drawer-push]': 'mode === "push"',
        '[class.mat-drawer-side]': 'mode === "side"',
        '[class.mat-drawer-fixed]': 'fixedInViewport',
        '[class.mat-drawer-opened]': 'opened'
      },
      standalone: false,
      template: "<ng-container *ngIf=\"componentPortal === null; else portal\">\n  <ng-content select=\"cx-sidebar-header\"></ng-content>\n\n  <ng-content select=\"cx-sidebar-content\"></ng-content>\n\n  <ng-content select=\"cx-sidebar-footer\"></ng-content>\n</ng-container>\n\n<ng-template #portal>\n  <section class=\"cx-sidebar-portal-container\">\n    <ng-template [cdkPortalOutlet]=\"componentPortal\"></ng-template>\n  </section>\n</ng-template>\n",
      styles: [":host.mat-drawer{position:relative;z-index:4;position:absolute;top:0;bottom:0;z-index:3;outline:0;box-sizing:border-box;color:inherit;transform:translate3d(-100%,0,0);max-width:484px;width:100%;height:100%;background:#fff;display:flex;flex-direction:column}:host.mat-drawer.mat-drawer-side{z-index:2}:host.mat-drawer.mat-drawer-end{right:0;transform:translate3d(100%,0,0)}:host.mat-drawer[style*=\"visibility: hidden\"]{display:none}:host.mat-drawer:not(.mat-drawer-side){box-shadow:0 24px 48px #20202029}:host.mat-drawer-fixed{position:fixed}\n"]
    }]
  }], () => [{
    type: i0.ElementRef
  }, {
    type: i2.ConfigurableFocusTrapFactory
  }, {
    type: i3.Platform
  }, {
    type: i0.NgZone
  }, {
    type: i2.InteractivityChecker
  }, {
    type: undefined,
    decorators: [{
      type: Optional
    }, {
      type: Inject,
      args: [DOCUMENT]
    }]
  }], {
    portalHost: [{
      type: ViewChild,
      args: [CdkPortalOutlet, {
        static: true
      }]
    }],
    componentPortal: [{
      type: Input
    }],
    fixedInViewport: [{
      type: Input
    }]
  });
})();
class CxSidebarContainerComponent extends MatDrawerContainer {
  constructor(dir, element, ngZone, changeDetectorRef, viewportRuler, document, renderer) {
    super(dir, element, ngZone, changeDetectorRef, viewportRuler);
    this.document = document;
    this.renderer = renderer;
    /**
     * Whether the body should have 'overflow:hidden' when the sidebar is opened
     *
     * @default false
     */
    this.hideBodyScrollbar = false;
  }
  updateContentMargins() {
    super.updateContentMargins();
    if (this.hideBodyScrollbar) {
      this.updateBodyScrollbar();
    }
  }
  updateBodyScrollbar() {
    const shoudBodyHaveScrollbar = !this.start?.opened && !this.end?.opened;
    const methodToCall = shoudBodyHaveScrollbar ? 'removeClass' : 'addClass';
    const htmlElement = this.document.body.parentElement;
    this.renderer[methodToCall](htmlElement, 'overflow-hidden');
  }
  static {
    this.ɵfac = function CxSidebarContainerComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || CxSidebarContainerComponent)(i0.ɵɵdirectiveInject(i6.Directionality, 8), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone), i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i1.ViewportRuler), i0.ɵɵdirectiveInject(DOCUMENT), i0.ɵɵdirectiveInject(i0.Renderer2));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: CxSidebarContainerComponent,
      selectors: [["cx-sidebar-container"]],
      contentQueries: function CxSidebarContainerComponent_ContentQueries(rf, ctx, dirIndex) {
        if (rf & 1) {
          i0.ɵɵcontentQuery(dirIndex, CxSidebarContainerContentComponent, 5);
          i0.ɵɵcontentQuery(dirIndex, CxSidebarComponent, 5);
        }
        if (rf & 2) {
          let _t;
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx._content = _t.first);
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx._allDrawers = _t);
        }
      },
      hostAttrs: [1, "mat-drawer-container", "mat-sidenav-container"],
      inputs: {
        hideBodyScrollbar: "hideBodyScrollbar"
      },
      standalone: false,
      features: [i0.ɵɵProvidersFeature([{
        provide: MAT_DRAWER_CONTAINER,
        useExisting: CxSidebarContainerComponent
      }, MatDrawerContainer]), i0.ɵɵInheritDefinitionFeature],
      ngContentSelectors: _c4,
      decls: 3,
      vars: 1,
      consts: [["class", "mat-drawer-backdrop", 3, "mat-drawer-shown", "click", 4, "ngIf"], [1, "mat-drawer-backdrop", 3, "click"]],
      template: function CxSidebarContainerComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef(_c3);
          i0.ɵɵtemplate(0, CxSidebarContainerComponent_div_0_Template, 1, 2, "div", 0);
          i0.ɵɵprojection(1);
          i0.ɵɵprojection(2, 1);
        }
        if (rf & 2) {
          i0.ɵɵproperty("ngIf", ctx.hasBackdrop);
        }
      },
      dependencies: [i4.NgIf],
      styles: [".mat-drawer-backdrop[_ngcontent-%COMP%]{inset:0;position:absolute;display:block;z-index:3;visibility:hidden}.mat-drawer-backdrop.mat-drawer-shown[_ngcontent-%COMP%]{visibility:visible;background-color:#202020;opacity:.16}.mat-drawer-transition[_ngcontent-%COMP%]   .mat-drawer-backdrop[_ngcontent-%COMP%]{transition-duration:.4s;transition-timing-function:cubic-bezier(.25,.8,.25,1);transition-property:background-color,visibility}.mat-drawer-container[_nghost-%COMP%]{position:relative;z-index:1;box-sizing:border-box;display:block;overflow:clip;color:inherit;background-color:transparent}.mat-drawer-container.mat-drawer-container-explicit-backdrop[_nghost-%COMP%]   .mat-drawer-side[_ngcontent-%COMP%]{z-index:3}.mat-drawer-container.ng-animate-disabled[_nghost-%COMP%]   .mat-drawer-backdrop[_ngcontent-%COMP%], .mat-drawer-container.ng-animate-disabled[_nghost-%COMP%]   .mat-drawer-content[_ngcontent-%COMP%], .ng-animate-disabled   .mat-drawer-container[_nghost-%COMP%]   .mat-drawer-backdrop[_ngcontent-%COMP%], .ng-animate-disabled   .mat-drawer-container[_nghost-%COMP%]   .mat-drawer-content[_ngcontent-%COMP%]{transition:none}"]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CxSidebarContainerComponent, [{
    type: Component,
    args: [{
      selector: 'cx-sidebar-container',
      host: {
        class: 'mat-drawer-container mat-sidenav-container'
      },
      providers: [{
        provide: MAT_DRAWER_CONTAINER,
        useExisting: CxSidebarContainerComponent
      }, MatDrawerContainer],
      standalone: false,
      template: "<div\n  class=\"mat-drawer-backdrop\"\n  (click)=\"_onBackdropClicked()\"\n  *ngIf=\"hasBackdrop\"\n  [class.mat-drawer-shown]=\"_isShowingBackdrop()\"\n></div>\n\n<ng-content select=\"cx-sidebar\"></ng-content>\n\n<ng-content select=\"cx-sidebar-container-content\"></ng-content>\n",
      styles: [".mat-drawer-backdrop{inset:0;position:absolute;display:block;z-index:3;visibility:hidden}.mat-drawer-backdrop.mat-drawer-shown{visibility:visible;background-color:#202020;opacity:.16}.mat-drawer-transition .mat-drawer-backdrop{transition-duration:.4s;transition-timing-function:cubic-bezier(.25,.8,.25,1);transition-property:background-color,visibility}:host.mat-drawer-container{position:relative;z-index:1;box-sizing:border-box;display:block;overflow:clip;color:inherit;background-color:transparent}:host.mat-drawer-container.mat-drawer-container-explicit-backdrop .mat-drawer-side{z-index:3}:host.mat-drawer-container.ng-animate-disabled .mat-drawer-backdrop,:host.mat-drawer-container.ng-animate-disabled .mat-drawer-content,.ng-animate-disabled :host.mat-drawer-container .mat-drawer-backdrop,.ng-animate-disabled :host.mat-drawer-container .mat-drawer-content{transition:none}\n"]
    }]
  }], () => [{
    type: i6.Directionality,
    decorators: [{
      type: Optional
    }]
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }, {
    type: i0.ChangeDetectorRef
  }, {
    type: i1.ViewportRuler
  }, {
    type: Document,
    decorators: [{
      type: Inject,
      args: [DOCUMENT]
    }]
  }, {
    type: i0.Renderer2
  }], {
    _allDrawers: [{
      type: ContentChildren,
      args: [CxSidebarComponent, {
        descendants: true
      }]
    }],
    _content: [{
      type: ContentChild,
      args: [CxSidebarContainerContentComponent]
    }],
    hideBodyScrollbar: [{
      type: Input
    }]
  });
})();
class CxSidebarContentComponent {
  static {
    this.ɵfac = function CxSidebarContentComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || CxSidebarContentComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: CxSidebarContentComponent,
      selectors: [["cx-sidebar-content"]],
      standalone: false,
      ngContentSelectors: _c0,
      decls: 3,
      vars: 0,
      consts: [["content", ""], ["cdkScrollable", ""]],
      template: function CxSidebarContentComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵelementStart(0, "div", 1, 0);
          i0.ɵɵprojection(2);
          i0.ɵɵelementEnd();
        }
      },
      dependencies: [i1.CdkScrollable],
      styles: ["[_nghost-%COMP%]{display:block;padding:24px;overflow:auto;height:100%}"]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CxSidebarContentComponent, [{
    type: Component,
    args: [{
      selector: 'cx-sidebar-content',
      standalone: false,
      template: "<div cdkScrollable #content>\n  <ng-content></ng-content>\n</div>\n",
      styles: [":host{display:block;padding:24px;overflow:auto;height:100%}\n"]
    }]
  }], null, null);
})();
class CxSidebarFooterComponent {
  static {
    this.ɵfac = function CxSidebarFooterComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || CxSidebarFooterComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: CxSidebarFooterComponent,
      selectors: [["cx-sidebar-footer"]],
      standalone: false,
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function CxSidebarFooterComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      styles: ["[_nghost-%COMP%]{display:flex;justify-content:flex-end;gap:16px;padding:24px;border-top:1px solid #ececec}[_nghost-%COMP%]:empty{display:none}"]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CxSidebarFooterComponent, [{
    type: Component,
    args: [{
      selector: 'cx-sidebar-footer',
      standalone: false,
      template: "<ng-content></ng-content>\n",
      styles: [":host{display:flex;justify-content:flex-end;gap:16px;padding:24px;border-top:1px solid #ececec}:host:empty{display:none}\n"]
    }]
  }], null, null);
})();
class CxSidebarHeaderComponent {
  static {
    this.ɵfac = function CxSidebarHeaderComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || CxSidebarHeaderComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: CxSidebarHeaderComponent,
      selectors: [["cx-sidebar-header"]],
      standalone: false,
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function CxSidebarHeaderComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      styles: ["[_nghost-%COMP%]{padding:24px;color:#202020;font-size:20px;font-weight:700;border-bottom:1px solid #ececec}[_nghost-%COMP%]:empty{display:none}"]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CxSidebarHeaderComponent, [{
    type: Component,
    args: [{
      selector: 'cx-sidebar-header',
      standalone: false,
      template: "<ng-content></ng-content>\n",
      styles: [":host{padding:24px;color:#202020;font-size:20px;font-weight:700;border-bottom:1px solid #ececec}:host:empty{display:none}\n"]
    }]
  }], null, null);
})();
const components = [CxSidebarContentComponent, CxSidebarContainerComponent, CxSidebarContainerContentComponent, CxSidebarComponent, CxSidebarHeaderComponent, CxSidebarFooterComponent];
class CxSidebarModule {
  static {
    this.ɵfac = function CxSidebarModule_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || CxSidebarModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: CxSidebarModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      imports: [CommonModule, MatSidenavModule, PortalModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CxSidebarModule, [{
    type: NgModule,
    args: [{
      declarations: [...components],
      imports: [CommonModule, MatSidenavModule, PortalModule],
      exports: [...components]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { CxSidebarComponent, CxSidebarContainerComponent, CxSidebarContainerContentComponent, CxSidebarContentComponent, CxSidebarFooterComponent, CxSidebarHeaderComponent, CxSidebarModule };
